import Link from 'next/link'
import styles from './Footer.module.scss'
import { navItems } from '../nav/NavItems'
import useMediaQuery from '../../hooks/useMediaQuery'
import Toggle from '../../components/toggle'
import SocialIcons from '../../components/social-icons/SocialIcons'
import Mailchimp from '../../components/mailchimp/embed'
import StoreContext from '../../context/store'
import { useContext } from 'react'

export default function Footer({fontClass}) {
	const { store } = useContext(StoreContext)
	const isBreakPoint = useMediaQuery(560)

	return (
			<footer className={`${styles.footer} ${fontClass}`}>
				<div className={`${styles.footer__wrapper} ${!isBreakPoint ? 'container' : undefined}`}>

					{/* menus */}
					<div className={styles.footer__menus}>
						{navItems.footerItems.map((group) => (
							<Menu title={group.title} className={styles.menu} key={group.title}>
								{group.links.map((link) => (
									<MenuItem link={link.link} name={link.title} key={link.title} />
								))}
							</Menu>
						))}
					</div>

					{/* newsletter */}
					<div className={styles.newsletter} >
						<Mailchimp title="Stay Connected" />
						<div className={styles.social_links}>
							<SocialIcons />
						</div>
					</div>

				</div>

				{/* credits */}
				<div className={styles.bottomBar}>
					<div className={`${styles.disclaimer} container`}>
						<ul className={styles.terms} aria-label="terms">
							<MenuItem link="/unsubscribe" name="unsubscribe" />
							<MenuItem link="/terms-of-use" name="terms of use" />
							<MenuItem link="/privacy-policy" name="privacy policy" />
							{/* <MenuItem link="/prop-65-warning" name="Prop 65 Warning" /> */}
						</ul>
						<ul className={styles.credits} aria-label="credits">
							<li>
								<Copywrite />
							</li>
							<li>
								C-10-0000241-LIC
							</li>
						</ul>
					</div>
				</div>
			</footer>
	);
}

function Menu(props) {
	const isBreakPoint = useMediaQuery(560)
	return (
		<>
			{isBreakPoint ? 
			<Toggle title={props.title} class={styles.toggle}>
				<ul>
				{props.children}
				</ul>
			</Toggle> 
			:
				<ul aria-label={props.title} className={props.className}>
				{props.children} 
			</ul>}
		</>
	)
}

function MenuItem(props) {
	return (
		<li className={styles.link}>
			<Link href={props.link} prefetch={false}> 
				{props.name}
			</Link>
		</li>
	)
}

function Copywrite() {
	const today = new Date();
	const year = today.getFullYear();
	return (
		<>
			© Purple Lotus {year}
		</>
	)
}